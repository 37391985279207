import React, { useEffect } from 'react'
import { Button, Dropdown, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { ItemType } from 'antd/es/menu/interface'
import { Header } from 'antd/lib/layout/layout'
import { useNavigate } from 'react-router-dom'
import { initialize } from 'redux-form'

// assets
import PlusIcon from '../../assets/icons/plus-icon.svg?react'
import BackIcon from '../../assets/icons/rollback-icon.svg?react'
import ChevronIcon from '../../assets/icons/chevron-down.svg?react'
import SalonDefaultAvatar from '../../assets/icons/salon-default-avatar.png'

// utils
import { ADMIN_PERMISSIONS, FORM, NOTI_LAYOUT_HEADER_SALONS_BTN_ID, NOTI_LAYOUT_HEADER_SALONS_DROPDOWN, PERMISSION, STRINGS } from '../../utils/enums'
import Permissions from '../../utils/Permissions'
import { pushEventToDataLayer } from '../../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_CONTEXT_MENU_TYPE } from '../../utils/dataLayerEnums'

// redux
import { RootState } from '../../reducers'
import { setSelectedCountry } from '../../reducers/selectedCountry/selectedCountryActions'

// components
import UserAvatar from '../AvatarComponents'
import HeaderSelectCountryForm, { IHeaderCountryForm } from '../HeaderSelectCountryForm'
import Notifications from '../Notifications/Notifications'

// hooks
import useBackUrl from '../../hooks/useBackUrl'

export type LayoutHeaderProps = {
	salonID: string | undefined
}

const LayoutHeader = (props: LayoutHeaderProps) => {
	const { salonID } = props

	const selectedSalon = useSelector((state: RootState) => state.selectedSalon.selectedSalon.data)
	const selectedSalonLoading = useSelector((state: RootState) => state.selectedSalon.selectedSalon.isLoading)
	const salonOptions = useSelector((state: RootState) => state.selectedSalon.selectionOptions.data) || []
	const selectedCountry = useSelector((state: RootState) => state.selectedCountry.selectedCountry)
	const googleAnalyticsScreenName = useSelector((state: RootState) => state.googleAnalytics.screenName)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [t] = useTranslation()

	const [backUrl] = useBackUrl(t('paths:salons'))

	useEffect(() => {
		if (!salonID) {
			dispatch(initialize(FORM.HEADER_COUNTRY_FORM, { countryCode: selectedCountry }))
		}
	}, [dispatch, selectedCountry, salonID])

	const getSalonMenuItems = (): ItemType[] => {
		const salonMenuItems: ItemType[] = salonOptions.map((item) => ({
			key: item.key,
			label: (
				<>
					<UserAvatar size={24} src={item.logo || SalonDefaultAvatar} fallBackSrc={SalonDefaultAvatar} className={'mr-2-5 flex-shrink-0'} /> {item.label}
				</>
			),
			onClick: () => navigate(t('paths:salons/{{salonID}}', { salonID: item.value })),
			className: cx(
				{ 'ant-menu-item-selected': selectedSalon?.id === item.value },
				'py-2-5 px-2 mb-2 font-medium min-w-0',
				NOTI_LAYOUT_HEADER_SALONS_DROPDOWN.SALON_BUTTON(item.value as string)
			)
		}))

		return [
			{
				type: 'group',
				key: 'group-salons',
				children: salonMenuItems,
				// maxHeight - 100vh - 170px - je potrebné zaistiť aby na nejakom menšom responzívnom zobrazení nešlo menu mimo obrazovku
				// čiže odratá sa vyška headera, výška buttonu "Pridať salón" + nejake marginy, paddingy
				style: { height: salonOptions?.length > 8 ? 400 : 'auto', maxHeight: 'calc(100vh - 170px)', overflowY: 'auto' }
			},
			{
				type: 'group',
				key: 'group-add-salon',
				className: '',
				children: [
					{
						type: 'divider',
						key: 'divider1',
						className: 'm-0'
					},
					{
						key: 'add-salon',
						className: 'font-medium button-add',
						icon: <PlusIcon className={'text-notino-pink'} />,
						onClick: () => {
							pushEventToDataLayer({
								event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION,
								context_menu_type: GA_CONTEXT_MENU_TYPE.ADDED_SALONS_MENU,
								screen_name: googleAnalyticsScreenName,
								selected_option: 'add_salon'
							})

							navigate(t('paths:salons/create'))
						},
						label: STRINGS(t).addRecord(t('loc:salón'))
					}
				]
			}
		]
	}

	const getSelectedSalonLabel = (hasPermission: boolean) => {
		const content = (
			<Row className={cx('m-2 flex items-center gap-2 min-w-0')} justify='space-between' wrap={false}>
				<Row wrap={false} className={'min-w-0 flex items-center gap-2-5'}>
					<UserAvatar size={24} src={selectedSalon?.logo?.resizedImages.thumbnail || SalonDefaultAvatar} fallBackSrc={SalonDefaultAvatar} className={'flex-shrink-0'} />
					{selectedSalon?.name && <span className='truncate leading-4 min-w-0 inline-block'>{selectedSalon.name}</span>}
				</Row>

				{hasPermission && <ChevronIcon className='items-center flex-shrink-0' />}
			</Row>
		)

		const labelClassName = 'bg-notino-grayLighter rounded-lg min-w-0 text-notino-black max-w-xs'

		if (hasPermission) {
			if (salonOptions.length === 0) {
				return (
					<Button
						onClick={() => {
							navigate(t('paths:salons/create'))
						}}
						type='primary'
						htmlType='button'
						className={'noti-btn'}
						icon={<PlusIcon />}
					>
						{STRINGS(t).addRecord(t('loc:salón'))}
					</Button>
				)
			}

			return (
				<Dropdown
					menu={{
						className: `shadow-md max-w-xs min-w-0 mt-5 noti-dropdown-header ${NOTI_LAYOUT_HEADER_SALONS_DROPDOWN.DROPDOWN_CLASSNAME}`,
						items: getSalonMenuItems()
					}}
					placement='bottomRight'
					trigger={['click']}
					overlayStyle={{ minWidth: 226 }}
					getPopupContainer={() => document.querySelector('#noti-header') as HTMLElement}
					onOpenChange={(open: boolean) => {
						if (open) {
							pushEventToDataLayer({
								event: DATA_LAYER_EVENTS.OPEN_CONTEXT_MENU,
								context_menu_type: GA_CONTEXT_MENU_TYPE.ADDED_SALONS_MENU,
								screen_name: googleAnalyticsScreenName
							})
						}
					}}
				>
					<button id={NOTI_LAYOUT_HEADER_SALONS_DROPDOWN.SHOW_DROPDOWN} type={'button'} className={cx(labelClassName, 'cursor-pointer')} tabIndex={0}>
						{content}
					</button>
				</Dropdown>
			)
		}

		return (
			<>
				<span className='hidden lg:inline-block pr-4 text-xs selected-salon-text'>{t('loc:zvolený salón')}:</span>
				<div className={cx(labelClassName)}>{content}</div>
			</>
		)
	}

	return (
		<Header className='shadow-md bg-notino-white sticky top-0 px-4 flex items-center w-full z-40' id={'noti-header'}>
			<Permissions
				allowed={[PERMISSION.PARTNER]}
				except={[...ADMIN_PERMISSIONS]}
				render={(hasPermission) =>
					(hasPermission || !!salonID) && (
						<Row className={cx({ 'justify-end': hasPermission }, 'min-w-0 w-full')} wrap={false}>
							{!hasPermission && (
								<Button
									onClick={() => {
										navigate(backUrl as string)
									}}
									icon={<BackIcon className={'text-white'} />}
									className={'noti-btn noti-admin-back-button h-8 text-notino-white self-center bg-notino-pink mr-2'}
									type={'default'}
									size={'small'}
									disabled={selectedSalonLoading}
								>
									{t('loc:Vrátiť sa do administrácie')}
								</Button>
							)}
							<Row className={cx('w-1/7 items-center min-w-0', { 'ml-auto': !hasPermission })} wrap={false}>
								{getSelectedSalonLabel(hasPermission)}
							</Row>
						</Row>
					)
				}
			/>
			<Permissions
				allowed={[PERMISSION.NOTINO]}
				render={(hasPermission) =>
					hasPermission &&
					!salonID && (
						<Row className={'justify-end min-w-0 w-full'} wrap={false}>
							<HeaderSelectCountryForm onSubmit={(data: IHeaderCountryForm) => dispatch(setSelectedCountry(data.countryCode))} />
						</Row>
					)
				}
			/>
			<Notifications />
		</Header>
	)
}
export default LayoutHeader
