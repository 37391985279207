import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from 'antd'

// assets
import DownloadIcon from '../assets/icons/download-icon.svg?react'
import CopyableIcon from '../assets/icons/copyable-icon.svg?react'
import CheckIcon from '../assets/icons/check-icon.svg?react'

// utils
import { handleAuthorizedDownload } from '../utils/request'
import { getAccessToken } from '../utils/auth'

const { Paragraph } = Typography

type Props = {
	link: string
	linkRaw: string
	name: string
	description: string
	entityUrl: string
	onDownload?: () => void
	onCopy?: () => void
}

const QrCode = (props: Props) => {
	const [t] = useTranslation()
	const { link, description, name, entityUrl, onDownload, onCopy, linkRaw } = props

	return (
		<div className={'flex'}>
			<img style={{ height: 240 }} className={'mr-6 block'} src={`${link}&t=${getAccessToken()}`} alt='qr code' crossOrigin={'anonymous'} />
			<div className={'flex flex-col justify-start overflow-hidden'}>
				<h4>{t('loc:Digitálny QR kód')}</h4>
				<span className={'mb-4 text-notino-grayDark'}>{description}</span>
				<div className={'flex gap-0 flex-col lg:flex-row lg:gap-2'}>
					<Button
						className={'noti-btn w-full lg:w-min mb-4'}
						href={`${link}&t=${getAccessToken()}`}
						onClick={(e) => {
							if (onDownload) {
								onDownload()
							}
							handleAuthorizedDownload(e, link, name)
						}}
						type={'primary'}
						htmlType={'button'}
						title={t('loc:Stiahnuť qr kód')}
						icon={<DownloadIcon width={24} />}
						download
					>
						{t('loc:Stiahnuť so šablónou')}
					</Button>
					<Button
						className={'noti-btn w-full lg:w-min mb-4'}
						href={`${link}&t=${getAccessToken()}`}
						onClick={(e) => {
							if (onDownload) {
								onDownload()
							}
							handleAuthorizedDownload(e, linkRaw, name)
						}}
						type={'primary'}
						htmlType={'button'}
						title={t('loc:Stiahnuť qr kód')}
						icon={<DownloadIcon width={24} />}
						download
					>
						{t('loc:Stiahnuť len QR kód')}
					</Button>
				</div>
				<Paragraph
					className={'w-full flex items-center overflow-hidden mb-2 bg-notino-grayLighter h-10 px-3 rounded-lg border border-solid border-notino-grayLight max-w-sm'}
					copyable={{
						text: entityUrl,
						onCopy,
						tooltips: t('loc:Kopírovať'),
						icon: [
							<CopyableIcon width={24} height={24} className={'text-notino-pink hover:text-notino-pink'} />,
							<CheckIcon className={'text-notino-pink hover:text-notino-pink medium-icon'} />
						]
					}}
				>
					<span className={'flex-1 text-notino-grayDarker truncate inline-block font-sm max-w-full'}>{entityUrl}</span>
				</Paragraph>
				<p className={'text-xs text-notino-grayDark leading-3'}>{t('loc:Ak je pre vás praktickejší URL link, môžete si ho skopírovať a zdieľať podľa potreby.')}</p>
			</div>
		</div>
	)
}

export default QrCode
