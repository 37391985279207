export enum DATA_LAYER_EVENTS {
	ACCOUNT_SIGN_UP = 'account_sign_up',
	SCREEN_VIEW = 'screen_view',
	SCREEN_DURATION = 'screen_duration',
	SEARCH = 'search',
	OPEN_CONTEXT_MENU = 'open_context_menu',
	SELECT_CONTEXT_MENU_OPTION = 'select_context_menu_option',
	CLICK_BUTTON = 'click_button',
	TOGGLE_SWITCH = 'toggle_switch',
	TOGGLE_CHECKBOX = 'toggle_checkbox',
	SELECT_SEGMENT = 'select_segment',
	OPEN_DROPDOWN_FILTER = 'open_dropdown_filter',
	SELECT_DROPDOWN_FILTER_OPTION = 'select_dropdown_filter_option',
	OPEN_RESERVATION_DETAIL = 'open_reservation_detail',
	CHANGE_RADIO_BUTTON_VALUE = 'change_radio_button_value',
	RESIZE = 'resize',
	DRAG = 'drag',
	OPEN_DROPDOWN = 'open_dropdown',
	SELECT_DROPDOWN_MENU_OPTION = 'select_dropdown_menu_option'
}

export enum GA_SCREEN_NAME {
	OTHER = 'other',
	OVERVIEW = 'overview',
	SALON_DETAIL = 'salon_detail',
	BILLING_INFORMATION = 'billing_information',
	INDUSTRY_AND_SERVICES = 'industry_and_services',
	ASSIGN_SERVICES = 'assign_services',
	SERVICES_SETTINGS = 'services_settings',
	SERVICE_DETAIL = 'service_detail',
	CUSTOM_SERVICE_DETAIL = 'custom_service_detail',
	CUSTOM_SERVICE = 'custom_service',
	COLLEAGUES = 'colleagues',
	CREATE_COLLEAGUE = 'create_colleague',
	COLLEAGUE_PERSONAL_INFO = 'colleague_personal_info',
	COLLEAGUE_WORKING_HOURS = 'colleague_working_hours',
	COLLEAGUE_ASSIGNED_SERVICES = 'colleague_assigned_services',
	COLLEAGUE_RESERVATION_HISTORY = 'colleague_reservation_history',
	WORKING_TIME_EDITOR = 'working_time_editor',
	WORKING_TIME_EDITOR_CONFIRM_CHANGE_TYPE = 'working_time_editor_confirm_change_type',
	RESERVATION_SETTINGS = 'reservation_settings',
	SMS_CREDIT = 'sms_credit',
	AFFILIATE_PROGRAM = 'affiliate_program',
	CLIENTS = 'clients',
	LIST_OF_RESERVATIONS = 'list_of_reservations',
	PLANNING_CALENDAR = 'planning_calendar',
	PLANNING_CALENDAR_DAILY = 'planning_calendar_daily',
	PLANNING_CALENDAR_WEEKLY = 'planning_calendar_weekly',
	PLANNING_CALENDAR_MONTHLY = 'planning_calendar_monthly',
	PLANNING_CALENDAR_RESERVATION_DETAIL = 'planning_calendar_reservation_detail',
	PERSONAL_INFORMATION = 'personal_information',
	LANGUAGE_SETTINGS = 'language_settings',
	DOCUMENTS = 'documents',
	DOCUMENT_DETAIL = 'document_detail',
	HELP_AND_SUPPORT = 'help_and_support',
	ABOUT_APPLICATION = 'about_application',
	ALBUMS = 'albums',
	NEW_ALBUM = 'new_album',
	EDIT_ALBUM = 'edit_album',
	NEW_CLIENT = 'new_client',
	CLIENT_DETAIL = 'client_detail',
	EXPERIENCE_RATING = 'experience_rating'
}

export const DEFAULT_SCREEN_NAME = GA_SCREEN_NAME.OTHER

export enum GA_SCREEN_CLASS {
	OTHER = 'other',
	OVERVIEW = 'overview',
	SALON_DETAIL = 'salon_detail',
	BILLING_INFORMATION = 'billing_information',
	INDUSTRY_AND_SERVICES = 'industry_and_services',
	ASSIGN_SERVICES = 'assign_services',
	SERVICES_SETTINGS = 'services_settings',
	SERVICE_DETAIL = 'service_detail',
	CUSTOM_SERVICE_DETAIL = 'custom_service_detail',
	CUSTOM_SERVICE = 'custom_service',
	COLLEAGUES = 'colleagues',
	CREATE_COLLEAGUE = 'create_colleague',
	COLLEAGUE_PERSONAL_INFO = 'colleague_personal_info',
	COLLEAGUE_WORKING_HOURS = 'colleague_working_hours',
	COLLEAGUE_ASSIGNED_SERVICES = 'colleague_assigned_services',
	COLLEAGUE_RESERVATION_HISTORY = 'colleague_reservation_history',
	WORKING_TIME_EDITOR = 'working_time_editor',
	RESERVATION_SETTINGS = 'reservation_settings',
	SMS_CREDIT = 'sms_credit',
	AFFILIATE_PROGRAM = 'affiliate_program',
	CLIENTS = 'clients',
	ADD_NEW_CLIENT = 'add_new_client',
	LIST_OF_RESERVATIONS = 'list_of_reservations',
	PLANNING_CALENDAR = 'planning_calendar',
	PERSONAL_INFORMATION = 'personal_information',
	LANGUAGE_SETTINGS = 'language_settings',
	DOCUMENTS = 'documents',
	DOCUMENT_DETAIL = 'document_detail',
	HELP_AND_SUPPORT = 'help_and_support',
	ABOUT_APPLICATION = 'about_application',
	ALBUMS = 'albums',
	NEW_ALBUM = 'new_album',
	EDIT_ALBUM = 'edit_album',
	NEW_CLIENT = 'new_client',
	CLIENT_DETAIL = 'client_detail',
	EXPERIENCE_RATING = 'experience_rating'
}

export enum GA_CONTEXT_MENU_TYPE {
	ACCOUNT_MENU = 'account_menu',
	ADDED_SALONS_MENU = 'added_salons_menu',
	NOTIFICATIONS_MENU = 'notifications_menu',
	SINGLE_NOTIFICATIONS_MENU = 'single_notifications_menu',
	SHOW_RESULTS = 'show_results',
	CALENDAR_MODE = 'calendar_mode',
	SHOW_CALENDAR = 'show_calendar',
	RESERVATION_DETAIL_CALENDAR = 'reservation_detail_calendar'
}

export enum GA_FILTER_TYPE {
	COLLEAGUE_ACCOUNT_STATUS = 'colleague_account_status',
	SERVICE_FILTER = 'service_filter',
	SELECT_MONTH = 'select_month',
	DATE_OF_CREATION = 'date_of_creation',
	PLANNED_DATE = 'planned_date',
	CATEGORY = 'category',
	STATUS = 'status',
	PAYMENT_METHOD = 'payment_method',
	CLIENT = 'client',
	COLLEAGUE = 'colleague',
	CREATED_BY = 'created_by'
}

export enum GA_RESERVATION_STATUS {
	PENDING = 'pending',
	APPROVED = 'approved',
	REALIZED = 'realized',
	NOT_REALIZED = 'not_realized',
	DECLINED = 'declined',
	CANCELLED = 'cancelled'
}

export enum GA_RESERVATION_TIME {
	PENDING = 'pending',
	PAST = 'past',
	FUTURE = 'future'
}

export enum GA_DROPDOWN_NAME {
	LANGUAGE_SELECTION = 'language_selection',
	COUNTRY_SELECTION = 'country_selection',
	SELECT_SERVICE_COLOR = 'select_service_color'
}
